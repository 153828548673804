.errorWrapper {
  padding: 16px;
  display: grid;
  gap: 24px;
  justify-items: center;
  align-content: center;
  min-height: calc(100vh - var(--header-height));
  min-height: calc(100svh - var(--header-height)); /* for Safari mobile */
  text-align: center;

  @media (--laptop) {
    gap: 32px;
    min-height: calc(100vh - 64px);
    min-height: calc(100svh - 64px);
  }
}

.headline {
  color: var(--color-grayscale-very-gray);
  font-family: var(--font-work-sans);
  font-size: 36px;
  font-weight: 700;
}

.paragraph {
  color: var(--color-grayscale-very-gray);
  font-size: 14px;
}

.buttonsWrapper {
  display: grid;
  justify-items: center;
  gap: 16px;
}
