.loginForm {
  display: grid;
  gap: 16px;
}

.passwordWrapper {
  display: grid;
  gap: 5px;

  a[data-variant='Link'] {
    margin-left: auto;
  }
}

.codeLogin {
  display: grid;
  gap: 24px;
  margin-top: 8px;
}
