.passwordSettingForm {
  display: grid;
  gap: 16px;

  button[data-variant='Link'] {
    font-weight: 400;
  }
}

.legalInfo {
  color: var(--color-grayscale-very-gray);
  font-size: 12px;
  line-height: 1.5;

  a {
    color: var(--color-primary-default);
    text-decoration: none;
    font-weight: 600;

    &:focus-visible {
      outline: 4px solid hsl(var(--color-primary-default--hsl) 0.5);
    }
  }
}
