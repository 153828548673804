.logout-loader {
  display: grid;
  gap: 16px;
  justify-items: center;
  margin-top: 15%;
  padding: 40px;

  &__redirect-copy {
    font-size: 14px;
    color: var(--color-grayscale-very-gray);
  }
}
